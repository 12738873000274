import { ReactElement } from 'react'
import { Link } from 'react-router-dom'

type Props = {
  link: string,
  img: string,
  title: string,
  disabledLink?: string,
  externalLink?: boolean,
} & typeof defaultProps

const defaultProps = {
  disabledLink: '',
  externalLink: false,
}

export default function CustomCard({
  link, img, title, disabledLink, externalLink,
}: Props): ReactElement {
  return externalLink ? (
    <a href={link} className={`card overflow-hidden mb-2 ${disabledLink ? 'disabled-link' : ''}`}>
      <img
        className="card-img-top"
        alt={title}
        src={img}
        style={{ height: '130px', objectFit: 'contain', padding: '10px' }}
      />
      <div className="card-body text-center">
        <h5 className="card-title mb-0 text-base font-weight-normal">{title}</h5>
      </div>
    </a>
  ) : (
    <Link to={link} className={`card overflow-hidden mb-2 ${disabledLink ? 'disabled-link' : ''}`}>
      <img
        className="card-img-top"
        alt={title}
        src={img}
        style={{ height: '130px', objectFit: 'contain', padding: '10px' }}
      />
      <div className="card-body text-center">
        <h5 className="card-title mb-0 text-base font-weight-normal">{title}</h5>
      </div>
    </Link>
  )
}

CustomCard.defaultProps = defaultProps
