import { createSlice } from '@reduxjs/toolkit'
import { Access } from 'objects/types'
import AccessServices from 'services/AccessServices'

export interface AccessState {
  access: Access,
}

const initialState: AccessState = {
  access: { workspaces: [], buckets: [], databases: [] },
}

export const accessSlice = createSlice({
  name: 'access',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(AccessServices.getAccess.fulfilled, (state, action) => {
      state.access = action.payload
    })
  },
})

export default accessSlice.reducer
