import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AccessState } from 'reducers/access'
import AccessServices from 'services/AccessServices'
import { RootState } from 'Store'
import bucketImage from 'assets/logos/bucket.png'
import databaseImage from 'assets/logos/database.png'
import CustomCard from 'components/CustomCard/CustomCard'
import Cloud9Card from 'components/Cloud9Card/'
import {
  bucketLink, keycloakLink, rdsLink,
} from './const'
import getWorkspaceLink from './utils'

export default function WorkspaceList(): ReactElement {
  const dispatch = useDispatch()
  const { access } = useSelector((state: RootState) => state.access) as AccessState

  useEffect(() => {
    dispatch(AccessServices.getAccess({}))
  }, [])

  return (
    <div className="cardscontainer">
      <div className="row">
        {access.workspaces.map(workspace => (
          <div key={`workspace${workspace.name}`} className="col-sm-6 col-md-3 col-lg-2 mb-2">
            <Cloud9Card
              title={workspace.name}
              link={`${keycloakLink}${getWorkspaceLink(workspace.region, workspace.workspace_id)}`}
              instanceId={workspace.instance_id}
            />
          </div>
        ))}
        {access.buckets.map(bucket => (
          <div key={`workspace${bucket.bucket_name}`} className="col-sm-6 col-md-3 col-lg-2 mb-2">
            <CustomCard
              img={bucketImage}
              title={bucket.name}
              link={`${keycloakLink}${bucketLink}${bucket.bucket_name}?region=eu-west-3&tab=objects`}
              externalLink
            />
          </div>
        ))}
        {access.databases.map(database => (
          <div key={`workspace${database.database_name}`} className="col-sm-6 col-md-3 col-lg-2 mb-2">
            <CustomCard
              img={databaseImage}
              title={database.name}
              link={`${keycloakLink}${rdsLink}?region=eu-west-3#database:id=${database.database_name};is-cluster=false`}
              externalLink
            />
          </div>
        ))}

      </div>
    </div>
  )
}
