import { useEffect, Suspense, ReactElement } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Router, Switch, Route } from 'react-router-dom'

import Loader from 'components/Loader'
import { auth } from '@osrdata/app_core'
import history from 'customHistory'
import 'App.css'
import { RootState } from 'Store'
import Home from 'views/Home'

export default function App(): ReactElement {
  const dispatch = useDispatch()
  const { isLogged } = useSelector((state: RootState) => state.user)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  return (
    <>
      <Suspense fallback={<Loader center />}>
        {isLogged && (
          <Router history={history}>
            <Switch>
              <Route component={Home} path="/" />
            </Switch>
          </Router>
        )}
      </Suspense>
    </>
  )
}
