/* eslint-disable @typescript-eslint/no-explicit-any */
import { get, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import URI from 'services/uri'
import { ThunkApiConfig } from 'types'

const getInstanceState = createAsyncThunk<any, any, ThunkApiConfig>(
  'users/getInstanceState',
  async (instanceId, thunkApi) => {
    try {
      const response = await get(`/aws_link/${URI.cloud9Instances}/${instanceId}/`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export async function StartInstance(instanceId: string): Promise<any> {
  try {
    await post(`/aws_link/${URI.cloud9Instances}/${instanceId}/`, {})
    return null
  } catch (e: any) {
    return {
      data: e.response.data,
      code: e.response.status,
    }
  }
}

const Cloud9Services = {
  getInstanceState,
}

export default Cloud9Services
