import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import workspaceImage from 'assets/logos/workspace.png'
import { RootState } from 'Store'
import { Cloud9InstancesState } from 'reducers/cloud9_instance'
import Cloud9Services, { StartInstance } from 'services/Cloud9Services'

type Props = {
  link: string,
  title: string,
  instanceId: string,
}

export default function Cloud9Card({
  link, title, instanceId,
}: Props): ReactElement {
  const dispatch = useDispatch()
  const { states } = useSelector((state: RootState) => state.instanceStates) as Cloud9InstancesState

  useEffect(() => {
    dispatch(Cloud9Services.getInstanceState(instanceId))
  }, [])

  const instanceState = states.find(({ instance_id: id }) => id === instanceId)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onClickHandler = async (e: any) => {
    e.stopPropagation()
    if (instanceState?.state !== 'running') {
      await StartInstance(instanceId)
    }
    window.location.href = link
  }

  const stateValue = instanceState ? instanceState.state : '...'
  return (
    <div aria-hidden="true" onClick={onClickHandler} className="card-body card overflow-hidden">
      <img
        className="card-img-top"
        alt={title}
        src={workspaceImage}
        style={{ height: '130px', objectFit: 'contain', padding: '10px' }}
      />
      <div className="card-body text-center">
        <h5 className="card-title mb-0 text-base font-weight-normal">{title}</h5>
      </div>
      <span className="card-footer text-center">{`State: ${stateValue}`}</span>
    </div>
  )
}
