/* eslint-disable @typescript-eslint/no-explicit-any */
import { get } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import URI from 'services/uri'
import { ThunkApiConfig } from 'types'

const getAccess = createAsyncThunk<any, any, ThunkApiConfig>(
  'users/getAccess',
  async thunkApi => {
    try {
      const response = await get(`/aws_link/${URI.access}/`, {})
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const AccessServices = {
  getAccess,
}

export default AccessServices
