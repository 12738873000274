import { createSlice } from '@reduxjs/toolkit'
import { InstanceState } from 'objects/types'
import Cloud9Services from 'services/Cloud9Services'

export interface Cloud9InstancesState {
  states: InstanceState[],
}

const initialState: Cloud9InstancesState = {
  states: [],
}

export const cloud9InstancesSlice = createSlice({
  name: 'cloud9Instances',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(Cloud9Services.getInstanceState.fulfilled, (state, action) => {
      let index = 0
      // eslint-disable-next-line no-restricted-syntax
      for (const val of state.states) {
        if (val.instance_id === action.payload.instance_id) {
          break
        }
        index += 1
      }
      if (index >= state.states.length) {
        state.states.push(action.payload)
      } else {
        state.states[index] = action.payload
      }
    })
  },
})

export default cloud9InstancesSlice.reducer
