import TopBar from 'components/TopBar/TopBar'
import WorkspaceList from 'components/WorkspaceList/WorkspaceList'
import { ReactElement } from 'react'
import './Home.scss'

const APP_NAME = 'aws_link'

export default function Home(): ReactElement {
  return (
    <>
      <TopBar appName={APP_NAME} />
      <main className="mastcontainer mastcontainer-no-mastnav">
        <WorkspaceList />
      </main>
    </>
  )
}
